import '../css/index.css';


function submitForm() {
    // Get form elements
    const form = document.getElementById("vehicleForm");
    const elements = form.elements;
    const formData = {};

    // Loop through form elements and store their values
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element.type !== "button") {
            const name = element.name;
            const label = element.dataset.label || '';
            if (element.type === "checkbox") {
                if (element.checked) {
                    if (formData[name]) {
                        formData[name].value.push(element.value);
                    } else {
                        formData[name] = {
                            value: [element.value],
                            label: label,
                        };
                    }
                }
            } else {
                formData[name] = {
                    value: element.value,
                    label: label,
                };
            }
        }
    }


    let url = wordpressData.rest_url + 'the-form/submit-form'
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
        .then(response => response.json())
        .then(data => {

            console.log('Form submitted successfully', data);
            const successSection = document.getElementById('success-section');
            //  const progress = document.querySelector('.progress');
            const formNav = document.getElementById('formNav');
            let sections = document.querySelectorAll('section[data-index]');

            sections.forEach((section, i) => {
                section.style.display = 'none'
            })



            formNav.style.display = 'none'
            successSection.style.display = 'block'
        })
        .catch(error => console.error('Error:', error));
}



document.addEventListener("DOMContentLoaded", function () {
    const submitButton = document.getElementById("submitButton");
    submitButton.addEventListener("click", function (event) {
        event.preventDefault();
        submitForm();
    });
});


document.addEventListener("DOMContentLoaded", function () {

    // Add an event listener to your checkbox
    document.getElementById('warningLightsOther').addEventListener('change', function () {
        // Grab the element you want to show/hide
        var otherIndicatorsField = document.getElementById('otherIndicatorsField');

        // Toggle the display style based on the checkbox's checked status
        otherIndicatorsField.style.display = this.checked ? 'block' : 'none';
    });

    document.getElementById('sensationsOther').addEventListener('change', function () {
        // Grab the element you want to show/hide
        var otherSensationsField = document.getElementById('otherSensationsField');

        // Toggle the display style based on the checkbox's checked status
        otherSensationsField.style.display = this.checked ? 'block' : 'none';
    });

    document.getElementById('soundsOther').addEventListener('change', function () {
        // Grab the element you want to show/hide
        var otherSoundsField = document.getElementById('otherSoundsField');

        // Toggle the display style based on the checkbox's checked status
        otherSoundsField.style.display = this.checked ? 'block' : 'none';
    });

    document.getElementById('soundLocationOther').addEventListener('change', function () {
        // Grab the element you want to show/hide
        var otherSoundLocationField = document.getElementById('otherSoundLocationField');

        // Toggle the display style based on the checkbox's checked status
        otherSoundLocationField.style.display = this.checked ? 'block' : 'none';
    });

    document.getElementById('affectedOther').addEventListener('change', function () {
        // Grab the element you want to show/hide
        var otherAffectedField = document.getElementById('otherAffectedField');

        // Toggle the display style based on the checkbox's checked status
        otherAffectedField.style.display = this.checked ? 'block' : 'none';
    });

    document.querySelectorAll('[name="consultedGarage"]').forEach( function(el) { 
        el.addEventListener('change', function () {

            var checkedVal = document.querySelector('[name="consultedGarage"]:checked');
            // Grab the element you want to show/hide
            var consultedGarageDetail = document.getElementById('consultedGarageDetail');

            consultedGarageDetail.style.display = checkedVal.value == 'Yes' ? 'block' : 'none';
        });
    });

    

    
    


    const radios = document.querySelectorAll('.custom-radio input');
    const checkboxes = document.querySelectorAll('.custom-checkbox input');

    // Function to update the background color of radio buttons
    function updateRadioBackground(radio) {
        // Clear background color for all radios with the same name
        document.querySelectorAll(`.custom-radio input[name="${radio.name}"]`).forEach(el => {
            el.closest('.custom-radio').style.backgroundColor = '';
        });
        // Set background color for the checked radio
        if (radio.checked) {
            //radio.closest('.custom-radio').style.backgroundColor = 'var(--settings_colour_1, rgb(214, 244, 255))';
            radio.closest('.custom-radio').classList.add('checked');
        } else {
            radio.closest('.custom-radio').classList.remove('checked');
        }
    }

    // Function to update the background color of checkboxes
    function updateCheckboxBackground(checkbox) {
        // Simply toggle the background color based on the checked state
        const parent = checkbox.closest('.custom-checkbox');
        //parent.style.backgroundColor = checkbox.checked ? 'var(--settings_colour_1, rgb(214, 244, 255))' : '';
        if (checkbox.checked) {
            parent.classList.add('checked');
        } else {
            parent.classList.remove('checked');
        }
    }

    radios.forEach(radio => {
        radio.addEventListener('change', (e) => updateRadioBackground(e.target));
    });

    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', (e) => updateCheckboxBackground(e.target));
    });


    function initializeFormPlugin(formPlugin) {
        const sections = formPlugin.querySelectorAll('section[data-index]');
        const navButtons = formPlugin.querySelectorAll('nav .button');
        let currentIndex = 0;

        function showSection(index) {
            sections.forEach((section, i) => {
                if (i === index) {
                    section.style.display = 'block';
                } else {
                    section.style.display = 'none';
                }
            });
        }

        function updateButtonsVisibility() {
            // Apply important to styles using cssText for the first nav button
            navButtons[0].style.cssText = currentIndex === 0 ? "display: none !important;" : "display: inline-flex !important;";

            // Apply important to styles using setProperty for the second nav button
            if (currentIndex === sections.length - 1) {
                navButtons[1].style.setProperty('display', 'none', 'important');
            } else {
                navButtons[1].style.setProperty('display', 'inline-flex', 'important');
            }

            // Show/hide submit button with important
            const submitButton = document.getElementById("submitButton");
            submitButton.style.cssText = currentIndex === sections.length - 1 ? "display: block !important;" : "display: none !important;";

            // Update the width of the .percentage element
            const percentageElement = document.querySelector('.percentage');
            const percentageWidth = ((currentIndex + 1) / sections.length) * 100 + '%';
            percentageElement.style.width = percentageWidth;

            updateIndexTitles(currentIndex);
        }


        function resetErrors() {
            let errors = document.querySelectorAll('.error-message');

            errors.forEach((error) => {
                error.remove()
            })
        }

        function validateInputs(inputs, currentSection) {
            resetErrors()
            let isValid = true;

            inputs.forEach(input => {

                const label = input.dataset.label || 'Field';
                const requiredLength = parseInt(input.dataset.length, 10);
                const format = input.dataset.format;
                let errorMessageElement = input.parentElement.querySelector('.error-message');

                // Remove any existing error message element
                if (errorMessageElement) {
                    errorMessageElement.remove();
                }

                if (!errorMessageElement) {
                    errorMessageElement = document.createElement('div');
                    errorMessageElement.classList.add('error-message', 'error'); // Added 'error' class
                }

                // Check if input is required and has no value
                if (input.hasAttribute('data-required') && input.value.trim() === '') {
                    errorMessageElement = document.createElement('div');
                    errorMessageElement.classList.add('error-message', 'error');
                    errorMessageElement.textContent = 'Required';
                    isValid = false;
                }

                if (requiredLength) {
                    if (input.value.trim().length !== requiredLength) {
                        errorMessageElement.textContent = `${label} must have ${requiredLength} digits.`;
                        isValid = false;
                    }
                }

                if (format) {
                    switch (format) {
                        case 'email':
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (!emailRegex.test(input.value.trim())) {
                                errorMessageElement.textContent = `Please enter a valid email address.`;
                                isValid = false;
                            }
                            break;
                        case 'number':
                            const numberRegex = /^\d+$/;
                            if (!numberRegex.test(input.value.trim())) {
                                errorMessageElement.textContent = `Please enter only numbers.`;
                                isValid = false;
                            }
                            break;
                        case 'telephone':
                            const telephoneRegex = /^\d{11}$/; // Assuming 10 digits for simplicity
                            if (!telephoneRegex.test(input.value.trim())) {
                                errorMessageElement.textContent = `Please enter a valid telephone number.`;
                                isValid = false;
                            }
                            break;

                    }
                }

                const parentElement = input.parentElement;
                parentElement.appendChild(errorMessageElement);

            });


            const contactMethodGroups = currentSection.querySelectorAll('[data-child-required]');

            contactMethodGroups.forEach(group => {
                const contactMethodCheckboxes = group.querySelectorAll('input[name]');
                const errorElement = group.querySelector('.checkbox-error');

                if (contactMethodCheckboxes && contactMethodCheckboxes.length > 0) {
                    const isChecked = Array.from(contactMethodCheckboxes).some(checkbox => checkbox.checked);

                    if (!isChecked) {
                        errorElement.textContent = 'At least one option is required';
                        errorElement.classList.add('error');
                        isValid = false;
                    } else {
                        errorElement.textContent = ''; // Clear any previous error message
                        errorElement.classList.remove('error');
                    }
                }
            });

            return isValid;
        }

        function updateIndexTitles(currentIndex) {

            let nextIndex = document.getElementById('nextIndex')
            let prevIndex = document.getElementById('previousIndex')

            if (currentIndex >= sections.length - 1) {
                nextIndex.textContent = ''
            } else {
                nextIndex.textContent = sections[currentIndex + 1].querySelector('h2').textContent;
            }

            if (currentIndex > 0) {
                prevIndex.textContent = sections[currentIndex - 1].querySelector('h2').textContent;
            } else {
                prevIndex.textContent = ''
            }

        }
        function goToNextSection(e) {
            e.preventDefault()
            // Get current section
            const currentSection = sections[currentIndex];

            // Get all required inputs in the current section
            const requiredInputs = currentSection.querySelectorAll('[data-required]');

            // Validate inputs
            if (!validateInputs(requiredInputs, currentSection)) {
                return; // Prevent advancing if inputs are invalid
            }

            if (currentIndex < sections.length - 1) {
                currentIndex++;
                showSection(currentIndex);

            }
            updateButtonsVisibility()

        }

        function goToPreviousSection(e) {
            e.preventDefault()
            if (currentIndex > 0) {
                currentIndex--;
                showSection(currentIndex);
                updateButtonsVisibility();
            }
        }

        navButtons[0].addEventListener('click', goToPreviousSection);
        navButtons[1].addEventListener('click', goToNextSection);

        showSection(currentIndex);
        updateButtonsVisibility();

    }

    const formPlugin = document.getElementById('form-plugin');
    initializeFormPlugin(formPlugin);
});

